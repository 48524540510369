import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './lang/en.json'
import es from './lang/es.json'
import fr from './lang/fr.json'
import de from './lang/de.json'
import it from './lang/it.json'
import ja from './lang/ja.json'
import ko from './lang/ko.json'
import appConfig from 'configs/app.config'

const resources = {
    en: {
        translation: en,
    },
    es: {
        translation: es,
    },
    fr: {
        translation: fr,
    },
    de: {
        translation: de,
    },
    it: {
        translation: it,
    },
    ja: {
        translation: ja,
    },
    ko: {
        translation: ko,
    }
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: appConfig.locale,
    lng: appConfig.locale,
    interpolation: {
        escapeValue: false,
    },
})

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    es: () => import('dayjs/locale/es'),
    fr: () => import('dayjs/locale/fr'),
    de: () => import('dayjs/locale/de'),
    it: () => import('dayjs/locale/it'),
    ja: () => import('dayjs/locale/ja'),
    ko: () => import('dayjs/locale/ko'),
}

export default i18n
