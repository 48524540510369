import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const PatreonUserContext = createContext({
    patreonUser: null,
    setPatreonUser: () => {}
});

// Create the provider
export const PatreonUserProvider = ({ children }) => {
    // Set up state for patreonUser, initializing it with data from localStorage
    const [patreonUser, setPatreonUser] = useState(() => {
        const storedUser = localStorage.getItem('patreon_user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    // Effect to synchronize patreonUser with localStorage
    useEffect(() => {
        if (patreonUser) {
            localStorage.setItem('patreon_user', JSON.stringify(patreonUser));
        } else {
            localStorage.removeItem('patreon_user');
        }
    }, [patreonUser]);

    // Effect to handle changes in localStorage
    useEffect(() => {
        const handleStorageChange = () => {
            const updatedUserData = JSON.parse(localStorage.getItem('patreon_user'));
            setPatreonUser(updatedUserData);
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    // Provide the context value to children
    return (
        <PatreonUserContext.Provider value={{ patreonUser, setPatreonUser }}>
            {children}
        </PatreonUserContext.Provider>
    );
};
