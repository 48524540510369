import React, { useEffect, useContext } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import history from './history';
import mockServer from './mock';
import appConfig from 'configs/app.config';
import './locales';
import { PatreonUserProvider, PatreonUserContext } from 'components/template/PatreonUser';

const environment = process.env.NODE_ENV;

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== 'production' && appConfig.enableMock) {
    mockServer({ environment });
}

function App() {
    // Ensure the useContext hook is used inside a function component that's wrapped with the provider
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <PatreonUserProvider>
                    <BrowserRouter history={history}>
                        <Theme>
                            <LayoutWrapper />
                        </Theme>
                    </BrowserRouter>
                </PatreonUserProvider>
            </PersistGate>
        </Provider>
    );
}

const LayoutWrapper = () => {
    const { patreonUser, setPatreonUser } = useContext(PatreonUserContext);

    useEffect(() => {
        const isAdFreeTier = () => {
            if (patreonUser && typeof patreonUser.is_ad_free !== 'undefined') {
                return patreonUser.is_ad_free;
            }
            return false;
        };

        const disableAdsForAuthenticatedUsers = () => {
            if (isAdFreeTier() && typeof window !== 'undefined') {
                const adContainers = document.querySelectorAll('.adsbygoogle');
                adContainers.forEach(container => {
                    container.style.display = 'none';
                });
            }
            else {
            }
        };

        disableAdsForAuthenticatedUsers();
    }, [patreonUser]);

    useEffect(() => {
        const storedUserData = localStorage.getItem('patreon_user');
        if (storedUserData) {
            setPatreonUser(JSON.parse(storedUserData));
        }
    }, [setPatreonUser]);

    return <Layout />;
};

export default App;
